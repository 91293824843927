body {
  padding: 0;
  margin: 0;
}

* {
  margin: 0px;
  padding: 0px;
}

.App {
  margin: 0px;
  padding: 0px;
}
