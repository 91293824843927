.grid-item-overview {
  font-size: 12px;
  text-align: center;
  font-weight: bolder;
  float: left;
}

.row-bg-overview {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.col-name {
  /* box-shadow: 0px 20px 5px -20px magenta; */
  box-shadow: 0px 5px 5px -5px magenta;
  height: 50px;
}
.item-name-align-left-overview {
  float: left;
  margin-top: 12px;
  padding: 0;
}
.quantity-items-align-right {
  float: right;
  margin-top: 12px;

  padding: 0;
}
.container {
  position: relative;
  width: 50%;
}
