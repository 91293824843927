.card {
  text-align: center;
  margin-top: 10px;
  margin-left: 12%;
  transition: ease-in-out 0.5s;
}

.price {
  font-weight: bold;
  text-align: center;

  font-size: 24px;
  color: black;
}

.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.description {
  margin-left: 60px;
  max-width: 30ch;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

#productImage {
  height: 28vw;
  width: 28vw;
  margin: 0;
  transition: ease-in-out 0.5s;
}

#productImage:hover {
  border: 2px solid rgba(0, 0, 0, 0.15);
}

.itemTextBlock:hover {
  border: 2px solid rgba(0, 0, 0, 0.15);
}

@media screen and (max-width: 1350px) {
  .card {
    margin-left: 0;
  }
  #productImage {
    height: 35vw;
    width: 35vw;
    margin: 0;
  }
}

@media screen and (max-width: 955px) {
  #productImage {
    height: 40vw;
    width: 40vw;
    margin-left: 0;
  }

  .card {
    margin-left: 0;
  }
}

@media screen and (max-width: 778px) {
  #productImage {
    height: 80vw;
    width: 80vw;
    margin-left: 0;
  }

  .card {
    margin-left: 0;
  }
}
