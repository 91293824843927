.App {
  text-align: center;
  margin-top: 100px;
  margin-bottom: 30px;
}

#h1 {
  margin-top: 120px;
}

#row {
  margin-left: 60px;
}

.content {
  position: absolute;
  z-index: 1;
  top: 22%;
  left: 35%;
  text-align: center;
  font-family: "Dancing Script";
  font-weight: 700;
  letter-spacing: -1px;
  font-size: 140px;
  font-weight: bolder;
  transition: ease all 0.5s;
  font-display: swap;
}

#babyNestjesVideoText {
  position: absolute;
  z-index: 1;
  color: rgb(28, 202, 36);
  top: 105px;
  left: -33px;
}

#handMadeVideoText {
  font-size: 100px;
}

#video {
  height: auto;
  width: 100%;
  margin-left: 10px;
}

@media screen and (max-width: 1800px) {
  .content {
    left: 37%;
    top: 15%;
    font-size: 70px;
  }

  #handMadeVideoText {
    font-size: 55px;
  }
  #babyNestjesVideoText {
    top: 60px;
    left: 2px;
  }
}

@media screen and (max-width: 768px) {
  .content {
    left: 37%;
    top: 15%;
    font-size: 50px;
  }
  #babyNestjesVideoText {
    top: 40px;
    left: -26px;
  }
  #handMadeVideoText {
    top: 80%;
    font-size: 35px;
  }
}

@media screen and (max-width: 576px) {
  #row {
    margin-left: 10px;
  }
}

@media screen and (max-width: 515px) {
  .content {
    top: 10%;
    left: 34%;
    font-size: 40px;
  }
  #babyNestjesVideoText {
    top: 26%;
    left: -24px;
  }
  #handMadeVideoText {
    top: 80%;
    font-size: 28px;
  }
}

@media screen and (max-width: 400px) {
  .content {
    visibility: hidden;
  }
}
